import request from '@/utils/request'

//内容管理 - 资讯条数
export function informationNum(data) {
  return request({
    url: '/informationGinseng/listCount',
    method: 'GET',
    params: data
  })
}

//内容管理 - 资讯首页一览
export function informationPage(query) {
  return request({
    url: '/informationGinseng/listPage',
    method: 'get',
    params:query
  })
}


//批量设置资讯下架
export function updateOffShelf(data) {
  return request({
    url: '/informationGinseng/informationUnShelve',
    method: 'post',
    data
  })
}
//批量设置资讯上架
export function updateOnShelf(data) {
  return request({
    url: '/informationGinseng/informationGrounding',
    method: 'post',
    data
  })
}
//资讯冻结
export function informationFreeze(data) {
  return request({
    url: '/informationGinseng/informationFreeze',
    method: "post",
    data
  })
}
//资讯解冻
export function informationThawing(data) {
  return request({
    url: '/informationGinseng/informationThawing',
    method: "post",
    data
  })
}
//内容管理 - 编辑 --> 资讯详情
export function selectById(data) {
  return request({
    url: '/informationGinseng/listById',
    method: 'GET',
    params: data
  })
}
//内容管理 -  资讯编辑
export function update(data) {
  return request({
    url: '/informationGinseng/update',
    method: 'post',
    data
  })
}
//内容管理 -  资讯新增
export function save(data) {
  return request({
    url: '/informationGinseng/save',
    method: 'post',
    data
  })
}
//内容管理 -  根据关键字获取所有发布机构/人 名称
export function getIssueName(data) {
  return request({
    url: '/serverInformation/getIssueName',
    method: 'get',
    params: data
  })
}
//查询出所有的服务商/产品资讯
export function getInformationAssociationVo(query) {
  return request({
    url: '/serverInformation/getInformationAssociationVo',
    method: 'get',
    params: query
  })
}
//查询出所有的专家
export function getSpecialistAssociationVo(query) {
  return request({
    url: '/serverInformation/getSpecialistAssociationVo',
    method: 'get',
    params: query
  })
}
//查询智选企业
export function getCompanyList(query) {
  return request({
    url: '/serverInformation/getCompanyList',
    method: 'get',
    params: query
  })
}
//文件上传签名
export function uploadsign() {
  return request({
    url: '/public/sign',
    method: 'get',
   
  })
}
//资讯查看一览
export function listUserCheckPage(query) {
  return request({
    url: '/informationGinseng/listUserCheckPage',
    method: 'get',
    params: query
  })
}
//资讯分享一览
export function listUserSharePage(query) {
  return request({
    url: '/informationGinseng/listUserSharePage',
    method: 'get',
    params: query
  })
}
//资讯查看人数
export function userCheck(query) {
  return request({
    url: '/informationGinseng/userCheck',
    method: 'get',
    params: query
  })
}
//资讯分享数
export function userShare(query) {
  return request({
    url: '/informationGinseng/userShare',
    method: 'get',
    params: query
  })
}



